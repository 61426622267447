const Menu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '/DashboardView',
        icon: 'icon-grid',
        translate: 'sidebar.nav.SINGLEVIEW',
        displayItem: true,
    },
    {
        name: 'Gestion des produits',
        translate: 'sidebar.nav.PRODUCTLISTVIEW',
        icon: 'icon-basket-loaded',
        path: '/ProductView',
        displayItem: true,
    },
    {
        name: 'Gestion des sources',
        translate: 'sidebar.nav.SOURCELISTVIEW',
        icon: "icon-target fa",
        path: '/SourcesView',
        displayItem: true,
        isAdmin: 1
    }

];

export default Menu;
