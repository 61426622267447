export const LOG_USER = 'LOG_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = 'UPDATE_USER';


/**
 * Update User infos
 */
export function userLogin(
    isLoggedData,

    userTokenData,
    userIsAdminData,
    userEmailData,
    userLastNameData,
    userFirstNameData,



) {
    return {
        type: LOG_USER,
        isLogged: isLoggedData,

        userToken: userTokenData,
        userIsAdmin: userIsAdminData,
        userEmail: userEmailData,
        userLastName: userLastNameData,
        userFirstName: userFirstNameData,


    };
}

export function userUpdateProfil(

    userLastNameData,
    userFirstNameData,


) {
    return {
        type: UPDATE_USER,

        userLastName: userLastNameData,
        userFirstName: userFirstNameData,

    };
}

export function userLogOut() {
    return { type: LOGOUT_USER };
}