import React, { Component } from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import crypto from 'crypto';
import { Link } from 'react-router-dom';
import { Input, CustomInput, Button } from 'reactstrap';

import CONFIG_APP from "../../venv/config.json"


import FormValidator from '../Forms/FormValidator.js';
import MSLogo from '../../assets/img/microsoft.svg';
import ServiceIcon from '../../assets/img/awake.svg';

import { connect } from 'react-redux';
import { userLogin, userLogOut } from '../../store/actions/users.actions';


import axios from 'axios';


import Swal from 'sweetalert2';


class LoginMS extends Component {

    state = {
        formLogin: {
            email: '',
            password: ''
        },
        apiLoading: false,
        showErrorMsg: false,
        errorMsg: ""
    }

    /**
     * Validate input using onChange event
     * @param  {String} formName The name of the form in the state object
     * @return {Function} a function used for the event
     */
    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    }

    onSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });
        if (!hasError) {
            let identifyForm = {
                user: this.state.formLogin.email.toLowerCase(),
                md5: crypto.createHash('md5').update(this.state.formLogin.password).digest("hex")
            }

            this.setState({
                apiLoading: true
            })


            let logResponse = await axios({
                method: 'post',
                url: CONFIG_APP.root_api + CONFIG_APP.login_ep,
                data: identifyForm,
                validateStatus: () => true  // NEEDED WHEN CALLING AN EXPRESS CLOUD FUNCTION

            }).catch((error) => {
                //console.log("error  : " + JSON.stringify(error));
                this.setState({
                    apiLoading: false
                });
            });

            if (logResponse.status != 200) {

                this.setState({
                    apiLoading: false,
                    showErrorMsg: true,
                    errorMsg: logResponse.data.error
                });

            } else {
                console.log(JSON.stringify(logResponse.data))

                /*
                {
                    "identityUser": "mzemori@yokis.fr",
                    "userFirstName": "Marc",
                    "userLastName": "Z",
                    "tokenUser": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eVVzZXIiOiJtemVtb3JpQHlva2lzLmZyIiwidXNlclJvbGUiOiJzdXBlcnZpc2V1ciIsImlhdCI6MTY1NjY2NTc0MCwiZXhwIjoxNjU2NjgwMTQwfQ.c0P3Q6kDE8oKhCu5BlVxT5sIBQ6TBfXA8Q2Jq952B1I"
                }
                */

                this.props.userLogin(
                    true,

                    logResponse.data.tokenUser,
                    logResponse.data.userRole,

                    logResponse.data.identityUser, //email
                    logResponse.data.userLastName,
                    logResponse.data.userFirstName,

                )
                if (logResponse.data.hasOwnProperty('tokenUser')) {
                    this.props.history.push('/DashboardView');
                }
                //
            }


        }

    }

    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return this.state[formName] &&
            this.state[formName].errors &&
            this.state[formName].errors[inputName] &&
            this.state[formName].errors[inputName][method]
    }

    render() {
        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center" style={{ 'backgroundColor': '#f5f7fa', 'paddingBottom': '25px' }}>
                        <a href="">
                            <img className="block-center rounded" src={ServiceIcon} alt="Logo" />
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">Connexion</p>
                        <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="Votre identifiant"
                                        invalid={this.hasError('formLogin', 'email', 'required') || this.hasError('formLogin', 'email', 'email')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "email"]'
                                        value={this.state.formLogin.email} />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                        </span>
                                    </div>
                                    {this.hasError('formLogin', 'email', 'required') && <span className="invalid-feedback">Champ requis</span>}
                                    {this.hasError('formLogin', 'email', 'email') && <span className="invalid-feedback">Format d'identifiant non valide</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="password"
                                        id="id-password"
                                        name="password"
                                        className="border-right-0"
                                        placeholder="Mot de passe"
                                        invalid={this.hasError('formLogin', 'password', 'required')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formLogin.password}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-lock"></em>
                                        </span>
                                    </div>
                                    <span className="invalid-feedback">Field is required</span>
                                </div>
                            </div>
                            {this.state.apiLoading ? (
                                <div>Chargement</div>
                            ) : (
                                <button className="btn btn-block btn-primary mt-3" type="submit">Se connecter</button>
                            )}
                            {this.state.showErrorMsg ? (
                                <div style={{ 'color': '#F20E00', 'textAlign': 'center' }}>{this.state.errorMsg}</div>
                            ) : (
                                null
                            )}

                        </form>

                    </div>
                </div>

            </div>
        );
    }
}



const mapStoreToProps = (store) => {
    return {
        isLogged: store.user.isLogged,
        userUid: store.user.userUid,
        userToken: store.user.userToken,
        userEmail: store.user.userEmail,
        userLastName: store.user.userLastName,
        userFirstName: store.user.userFirstName,

        userAvatar: store.user.userAvatar
    }
}
const mapDispatchtoProps = {
    userLogin,
}

export default connect(mapStoreToProps, mapDispatchtoProps)(LoginMS);



