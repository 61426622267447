const CONFIG_APP = {


    APP_NAME: "Sentinel Awake",
    APP_VERS: "1.0.0",
    APP_DEBUG: true,





}

export default CONFIG_APP;