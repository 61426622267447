import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

//import { Login } from '@microsoft/mgt-react';
import LoginMS from './components/Pages/Login'


/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

//const Login = lazy(() => import('./components/Pages/Login'));


const DashboardView = lazy(() => import('./components/DashboardView/DashboardView'));
const ProfilView = lazy(() => import('./components/ProfilView/ProfilView'));

const SourcesView = lazy(() => import('./components/SourcesView/SourcesView'));
const SourceEditView = lazy(() => import('./components/SourceEditView/SourceEditView'));
const SourceCreateView = lazy(() => import('./components/SourcesView/SourceCreateView'));



const ProductView = lazy(() => import('./components/ProductView/ProductView'));
const ProductSourceView = lazy(() => import('./components/ProductView/ProductSourceView'));
const ProductCreateView = lazy(() => import('./components/ProductView/ProductCreateView'));
const ProductCreateSourceView = lazy(() => import('./components/ProductView/ProductCreateSourceView'));
const ProductEditSourceView = lazy(() => import('./components/ProductView/ProductEditSourceView'));




const UsersView = lazy(() => import('./components/UsersView/UsersView'));
const ProfilEditView = lazy(() => import('./components/ProfilEditView/ProfilEditView'));

const GroupsView = lazy(() => import('./components/GroupsView/GroupsView'));
const GroupEditView = lazy(() => import('./components/GroupEditView/GroupEditView'));
const GroupCreateView = lazy(() => import('./components/GroupCreateView/GroupCreateView'));

//const SubMenu = lazy(() => import('./components/SubMenu/SubMenu'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(LoginMS)} />

                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/DashboardView" component={waitFor(DashboardView)} />


                                    <Route path="/SourcesView" component={waitFor(SourcesView)} />
                                    <Route path="/SourceEditView" component={waitFor(SourceEditView)} />
                                    <Route path="/SourceCreateView" component={waitFor(SourceCreateView)} />

                                    <Route path="/ProductView" component={waitFor(ProductView)} />
                                    <Route path="/ProductCreateView" component={waitFor(ProductCreateView)} />
                                    <Route path="/ProductSourceView" component={waitFor(ProductSourceView)} />
                                    <Route path="/ProductCreateSourceView" component={waitFor(ProductCreateSourceView)} />
                                    <Route path="/ProductEditSourceView" component={waitFor(ProductEditSourceView)} />



                                    <Route path="/UsersView" component={waitFor(UsersView)} />
                                    <Route path="/ProfilEditView" component={waitFor(ProfilEditView)} />

                                    <Route path="/GroupsView" component={waitFor(GroupsView)} />
                                    <Route path="/GroupCreateView" component={waitFor(GroupCreateView)} />
                                    <Route path="/GroupEditView" component={waitFor(GroupEditView)} />
                                    <Redirect to="/login" />

                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);
